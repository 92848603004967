import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { siteLinks } from '@agria/theme/src/const/siteLinks';
import { Container } from '../../container/Container';
import { createMarkup, useBackgroundColor } from '../../../utils';
import { Typography } from '../../typography/Typography';
import { LogoInverse, SslSecure, LogoIE } from '../../svg';
import { Stack } from '../../stack/Stack';
import { Divider } from '../../divider/Divider';
import { LinkTo } from '../../linkTo/LinkTo';
import tokens from './tokens';
import { ThemeColorsKeys } from '../../../styles/tokens/colors';
import { CookiePreferenceButton } from '../../cookiePreferenceButton/CookiePreferenceButton';

// Interface

export interface SimpleFooterProps {
  footerTerms: string;
}

// Styles

const StyledSimpleFooter = styled.div`
  ${({ theme: { space } }) => css`
    ${useBackgroundColor(tokens.backgroundColour as ThemeColorsKeys)};
    padding: ${space[tokens.footerPaddingTop]} 0 ${space.fluidXxLarge};
  `};
`;

const StyledSmallLinks = styled.ul`
  ${({ theme: { space, media, colors } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xxSmall};

    li {
      a {
        text-decoration: ${tokens.footerLinksTextDecoration};
        color: ${colors[tokens.footerColor as ThemeColorsKeys]};

        &:hover,
        &:focus {
          color: ${colors.utilityWhite};
        }
      }
    }

    @media (min-width: ${media.large}) {
      flex-direction: row;
      gap: ${space.small};

      li {
        + li {
          border-left: solid 1px
            ${colors[tokens.footerLinksBorderColor as ThemeColorsKeys]};
          padding-left: ${space.small};
        }
      }
    }
  `};
`;

const StyledSecondaryNavigation = styled.div`
  ${({ theme: { media, colors, space } }) => css`
    padding: ${space.fluidLarge} 0;
    background-color: ${colors.brandTwo100};

    + div {
      padding-top: ${space.fluidLarge};
    }

    @media (min-width: ${media.large}) {
    }
  `};
`;

const StyledSslSecureContainer = styled.div`
  ${({ theme: { media } }) => css`
    width: 125px;

    @media (min-width: ${media.small}) {
      width: 167px;
    }
  `};
`;

// JSX test

export const SimpleFooter: FC<SimpleFooterProps> = ({ footerTerms }) => {
  let secondaryNavigation;

  // for secondary nav that isn't ireland or uk
  if (tokens.includeSecondaryNavOuter) {
    secondaryNavigation = [
      {
        label: 'Cookie policy',
        url: `${siteLinks.cookiePolicy}`,
      },
      {
        label: 'Privacy policy',
        url: `${siteLinks.privacyPolicy}`,
      },
    ];
  }

  // for ireland
  if (tokens.includeSecondaryNav) {
    secondaryNavigation = [
      {
        label: 'Terms and conditions',
        url: `${siteLinks.importantDocuments}`,
      },
      {
        label: 'Terms of business',
        url: `${siteLinks.importantDocuments}`,
      },
      {
        label: 'Privacy policy',
        url: `${siteLinks.importantDocuments}`,
      },
    ];
  }

  return (
    <StyledSimpleFooter>
      {tokens.includeSecondaryNavOuter && secondaryNavigation && (
        <StyledSecondaryNavigation>
          <Container>
            <StyledSmallLinks>
              <Typography component="li" variant="bodySmall" key="onetrust">
                <CookiePreferenceButton
                  buttonLabel="Manage your cookies"
                  noButtonPadding
                />
              </Typography>
              {secondaryNavigation?.map((nav, index) =>
                nav.url ? (
                  <Typography component="li" variant="bodySmall" key={index}>
                    <LinkTo to={nav.url}>{nav.label}</LinkTo>
                  </Typography>
                ) : null
              )}
            </StyledSmallLinks>
          </Container>
        </StyledSecondaryNavigation>
      )}
      <Container>
        <Stack gap="large">
          {tokens.includeInverseLogo && (
            <>
              <LogoInverse aria-hidden="true" />{' '}
              <Divider inverse aria-hidden="true" />
            </>
          )}

          {tokens.includeSecondaryNav && secondaryNavigation && (
            <StyledSmallLinks>
              {secondaryNavigation?.map((nav, index) =>
                nav.url ? (
                  <Typography component="li" variant="bodySmall" key={index}>
                    <LinkTo to={nav.url}>{nav.label}</LinkTo>
                  </Typography>
                ) : null
              )}
            </StyledSmallLinks>
          )}
          <Typography
            variant="bodySmall"
            color={tokens.footerColor as ThemeColorsKeys}
            component="div"
            suppressHydrationWarning
          >
            {createMarkup(footerTerms)}
          </Typography>
          <StyledSslSecureContainer>
            <SslSecure title="SSL Secure logo" />
          </StyledSslSecureContainer>
        </Stack>
      </Container>
    </StyledSimpleFooter>
  );
};

export default SimpleFooter;
